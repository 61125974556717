<template>
  <div class="background">
    <div class="flex algin">
      <div class="app-title">证书管理页面
      </div>
      <el-button id="app_back_btn" @click="onBack" class="app_back_btn">返回首页</el-button>
    </div>


<el-dialog
  title="提示"
  :visible.sync="dialognew1"
  width="50%"
  center>
  <el-input v-model="inputNote" placeholder="请输入备注"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialognew1 = false">取 消</el-button>
    <el-button type="primary" @click="editNote">确 定</el-button>
  </span>
</el-dialog>

    <el-dialog title="证书下载" :visible.sync="dialogVisible" width="30%">
      <el-button type="primary" @click="downloadP12File()">证书文件</el-button>
      <el-button type="primary" @click="downloadMobileFile()">描述文件</el-button>
      <el-button type="primary" @click="invoicesLinkZip()">证书压缩包</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>


    <div class="center-container-cer">
      <div style="margin-top: 25px;margin-bottom: 25px;margin-left: 60px;margin-right: 60px;">
        <el-input placeholder="请输入UDID或备注进行搜索" v-model="udidvalue" class="input-with-select" clearable>
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </div>
      <el-button class="buyButton" style="width:20%;" type="success" @click="onBuyCer">购买证书</el-button>
      <el-button class="buyButton" style="width:20%;" type="danger" @click="onsign">在线签名</el-button>

      <el-table v-loading="loading" :data="tableData" style="width: 100%" max-height="430">

        <el-table-column prop="udid" label="UDID" align="center">
        </el-table-column>

        <el-table-column prop="cerStatus" label="证书状态" align="center">
          <template slot-scope="scope">
            <label :class="{ 'text-red': scope.row.cerbool == false, 'text-green': scope.row.cerbool == true }">
              {{ scope.row.cerStatus }}
            </label>
          </template>

        </el-table-column>
       
        <el-table-column prop="cdKey" label="兑换手机APP" align="center">
          <template slot-scope="scope">
            <a v-if="scope.row.is_enable" :href="scope.row.cdUrl" target="_blank">点击兑换手机APP</a>
            <a v-else>{{ scope.row.cdKey }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="download" label="证书下载(密码1)" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_enable"
              @click="invoicesLink(scope.row.download, scope.row.deviceId, scope.row.p12_password, scope.row.is_enable, scope.row.index)"
              type="success">下载</el-button>
            <el-button v-else
              @click="invoicesLink(scope.row.download, scope.row.deviceId, scope.row.p12_password, scope.row.is_enable, scope.row.index)"
              type="warning">检测状态</el-button>
            <br>
          </template>
        </el-table-column>
        <el-table-column prop="class" label="备注" align="center">
          <template slot-scope="scope">
            <el-button
              @click="invoicesP12(scope.row.download, scope.row.class,scope.row.index )"
              type="success">{{scope.row.class}}</el-button>
            <br>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="block">
      <span class="demonstration">.</span>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="20" layout="prev, pager, next, jumper" :total="totalPage">
      </el-pagination>
    </div>
  </div>
</template>

<style>
.cer_input-with-select {
  width: 60%;
}

.container {
  flex-direction: row;
  padding: 5px;
}

.login-button {
  height: 75px;
  line-height: 75px;
}

.table_content_down {
  top: 20px;
  position: relative;
}

.text-red {
  color: red;
}

.text-green {
  color: rgb(0, 0, 0);
}

.center-container-cer {
  background-color: white;
  width: 70%;
  min-width: 340px;
  height: 560px;
}
</style>

<script>

import { Loading, Message } from 'element-ui'
import FileSaver from "file-saver";
import JSZip from 'jszip'
import { getCerDevice } from '@/api/member'
import { downloadP12 } from '@/api/download'
import { downloadMobile } from '@/api/download'
import { checkP12 } from '@/api/download'

import { setP12Note } from '@/api/download'

import { async } from "q";
export default {
  data() {
    return {
      tableData: null,
      loading: true,
      udidvalue: "",
      dialogVisible: false,
      cerId: "",
      deviceId: "",
      password: "",
      currentPage: 1,
      totalPage: 100,
      dialognew1:false,
      inputNote:"",
      inputCerId:"",
      inputIndex:0
    }
  },
  methods: {
    async getMemberSelf() {

      var userData;

      var numPage = 20;

      if (this.udidvalue == "") {
        userData = await getCerDevice(this.currentPage * numPage - numPage)
      } else {
        userData = await getCerDevice(this.currentPage * numPage - numPage, this.udidvalue);
      }

      // 判断是否登录成功
      if (userData != null) {
        var newData = [];

        if (userData.data == null) {
          this.loading = false;
          return;
        }

        var newData = new Array();
        this.totalPage = userData.paging.totals;
        for (var i = 0; i < userData.data.length; i++) {
          var data = userData.data[i];
          var newDic = {};
          this.onSetP12Data(data, newDic, i);
          newData.push(newDic);
        }

        this.tableData = newData;
        this.loading = false;
      } else {
        alert("请先进行登录");
        this.$router.push({
          path: `/login`,
        })
      }
    },

    onBuyCer() {
      this.$router.push({
        path: '/cerBuy'
      })
    },


  onSetP12Data(data, newDic, i) {
      var cerStatusStr = "检测中";

      // 先看外层note是否有值？
      var noteStr = data.note;

      // 如果外层note没值，那么使用获取的设备类型。
      if (noteStr == "") {
        noteStr = this.convertDeviceName(data.device.product);
      }

      var levelStr = "";
      if (data.level == 1) {
        levelStr = "普通"
      } else if (data.level == 2) {
        levelStr = "稳定"
      } else if (data.level == 3) {
        levelStr = "豪华"
      } else if (data.level == 999) {
        levelStr = "独立池"
    } else if (data.level == 5) {
        levelStr = "经济版"
      } else if (data.level == 4) {
        levelStr = "预约版"
      }

      // 如果设备类型也没获取，再看看内部的note是否有值（之前添加的备注格式）
      if (noteStr == "") {
        noteStr = data.device.note;
      }
      newDic["udid"] =  data.device.udid;
      newDic["class"] = noteStr;

      if (noteStr.length == 0){
        newDic["classEnable"] = false;
      }else{
        newDic["classEnable"] = true;
      }

      newDic["deviceId"] = data.device.id;

      if (data.cd_key != undefined) {
        newDic["cdKey"] = data.cd_key.out_id;
      }
      newDic["cdUrl"] = "https://install.dumpapp.com/home?UDID=" + data.device.udid;

      if (data.cd_key != undefined) {
        newDic["cdKey"] = data.cd_key.out_id;
        newDic["cdUrl"] = "https://install.dumpapp.com/home?UDID=" + data.device.udid + "&outId=" + data.cd_key.out_id;
      }

      newDic["createTime"] = this.formatDate(data.created_at);
      newDic["p12_password"] = data.p12_password;

      if (data.p12_is_active) {
        cerStatusStr = "有效"
        newDic["cerbool"] = true;
      } else {
        cerStatusStr = "无效"
        newDic["cerbool"] = false;
      }

      if (data.is_enable == false) {
        newDic["cdKey"] = "苹果处理中"
        newDic["cerStatus"] = "苹果处理中"
        levelStr = "苹果处理中"
      }

      newDic["cerStatus"] = cerStatusStr + "  (" + levelStr + ")";
      newDic["download"] = data.id;
      newDic["level"] = levelStr;
      newDic["is_enable"] = data.is_enable;
      newDic["index"] = i;
    },

    onsign() {
      window.open("https://dumpappdoc.feishu.cn/docx/doxcnJIcjkorQBoFoOWPXy8X03f");
    },
    async onBack() {
      this.$router.push({
        path: `/`,
      })
    },

    async onSearch() {
      if (this.udidvalue == "") {
        this.getMemberSelf();
        return;
      }

      this.getMemberSelf();
    },

    formatDate(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    },

    routerPay() {
      this.$router.push({
        path: `/pay`,
      })
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },

    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.getMemberSelf();
    },

    async editNote() {
      this.dialognew1 = false;
      var data = await setP12Note(this.inputCerId, this.inputNote);
      var newDic = {};
      this.onSetP12Data(data, newDic, this.inputIndex);

      var tempDatas = []
      for (var i=0; i<this.tableData.length; i++){
        var tData = this.tableData[i];
        if (i == this.inputIndex){
          console.log("inputIndex" , this.inputIndex);
          tempDatas.push(newDic);
        }else{
          tempDatas.push(tData);
        }
      }


      this.tableData = tempDatas;
      
    },

    async invoicesP12(cerId, note, index) {
      this.inputNote = note;
      this.inputCerId = cerId;
      this.inputIndex = index;
      this.dialognew1 = true;
    },


    async invoicesLink(cerId, deviceId, password, is_enable, index) {

      if (is_enable == false) {
        // 检查证书
        var data = await checkP12(cerId);
        var newDic = {};
        this.onSetP12Data(data, newDic, index);

        // 如果还是预约
        if (newDic.is_enable == false) {
          Message({
            message: "苹果处理中...",
            type: 'warning',
            duration: 1.5 * 1000,
            offset: document.body.clientHeight * 0.5 - 100
          });
          return;
        }else{
          var tempDatas = []
          for (var i=0; i<this.tableData.length; i++){
            var tData = this.tableData[i];
            if (i == index){
              tempDatas.push(newDic);
              continue;
            }
            tempDatas.push(tData);
          }
        }
      }

      // true 的话直接显示
      this.cerId = cerId;
      this.deviceId = deviceId;
      this.password = password;
      this.dialogVisible = true;
    },

    invoicesCerId(cerId, deviceId, password) {
      this.$copyText(cerId).then(
        (res) => {
          Message({
            message: "证书ID已复制",
            type: 'success',
            duration: 1.5 * 1000,
            offset: document.body.clientHeight * 0.5 - 100
          })
        },
        (err) => {
        }
      );


    },

    async invoicesLinkmob(cerId, deviceId) {
      this.downloadMobileFile(cerId, deviceId);
    },

    async invoicesLinkZip() {
      var cerId = this.cerId;
      var deviceId = this.deviceId;
      var password = this.password;

      //  this.downloadMobileFile(cerId, deviceId);
      let p12Data = await downloadP12(cerId, deviceId);
      let mobData = await downloadMobile(cerId, deviceId);

      let zip = new JSZip()
      zip.file("证书文件" + "(密码:" + password + ")" + ".p12", p12Data, { blob: true })
      zip.file("描述文件" + ".mobileprovision", mobData, { blob: true })

      zip.generateAsync({
        type: "blob"
      }).then((blob) => {
        FileSaver.saveAs(blob, "证书" + "(" + cerId + ")" + ".zip")
      }, (err) => {
        alert('导出失败')
      })
    },

    async downloadP12File() {
      var cerId = this.cerId;
      var deviceId = this.deviceId;
      let res = await downloadP12(cerId, deviceId);
      let blob = new Blob([res])
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', "developer.p12")
      document.body.appendChild(link)
      link.click()
    },

    async downloadMobileFile() {
      var cerId = this.cerId;
      var deviceId = this.deviceId;
      let res = await downloadMobile(cerId, deviceId);
      let blob = new Blob([res])
      let url = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', "developer.mobileprovision")
      document.body.appendChild(link)
      link.click()
    },


    convertDeviceName(devName) {
      if (devName == "iPhone5,1") return "iPhone 5";
      if (devName == "iPhone5,2") return "iPhone 5";
      if (devName == "iPhone6,1") return "iPhone 5s";
      if (devName == "iPhone6,2") return "iPhone 5s";
      if (devName == "iPhone7,2") return "iPhone 6";
      if (devName == "iPhone7,1") return "iPhone 6 Plus";
      if (devName == "iPhone8,1") return "iPhone 6s";
      if (devName == "iPhone8,2") return "iPhone 6s Plus";
      if (devName == "iPhone8,4") return "iPhone SE";
      if (devName == "iPhone9,1") return "iPhone 7";
      if (devName == "iPhone9,3") return "iPhone 7";
      if (devName == "iPhone9,2") return "iPhone 7 Plus";
      if (devName == "iPhone9,4") return "iPhone 7 Plus";
      if (devName == "iPhone10,1") return "iPhone 8";
      if (devName == "iPhone10,4") return "iPhone 8";
      if (devName == "iPhone10,2") return "iPhone 8 Plus";
      if (devName == "iPhone10,5") return "iPhone 8 Plus";
      if (devName == "iPhone10,3") return "iPhone X";
      if (devName == "iPhone10,6") return "iPhone X";
      if (devName == "iPhone11,2") return "iPhone XS";
      if (devName == "iPhone11,4") return "iPhone XS Max";
      if (devName == "iPhone11,6") return "iPhone XS Max";
      if (devName == "iPhone11,8") return "iPhone XR";
      if (devName == "iPhone12,1") return "iPhone 11";
      if (devName == "iPhone12,3") return "iPhone 11 Pro";
      if (devName == "iPhone12,5") return "iPhone 11 Pro Max";
      if (devName == "iPhone12,8") return "iPhone SE";
      if (devName == "iPhone13,1") return "iPhone 12 Mini";
      if (devName == "iPhone13,2") return "iPhone 12";
      if (devName == "iPhone13,3") return "iPhone 12 Pro";
      if (devName == "iPhone13,4") return "iPhone 12 Pro Max";
      if (devName == "iPhone14,2") return "iPhone 13 Pro";
      if (devName == "iPhone14,3") return "iPhone 13 Pro Max";
      if (devName == "iPhone14,4") return "iPhone 13 Mini";
      if (devName == "iPhone14,5") return "iPhone 13";

      if (devName == "iPhone14,6") return "iPhone SE3";
      if (devName == "iPhone14,7") return "iPhone 14";
      if (devName == "iPhone14,8") return "iPhone 14 Plus";

      if (devName == "iPhone15,2") return "iPhone 14 Pro";
      if (devName == "iPhone15,3") return "iPhone 14 Pro Max";


      return ""
    },

  },


  mounted() {
    this.getMemberSelf();
  },
}
</script>