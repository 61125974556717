<template>
  <el-container>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#F5F5F5"
      active-text-color="#409EFF"
      @select="handleSelect"
    >
      <el-submenu index="1">
        <template slot="title">手机 APP</template>
        <el-menu-item index="2-1">APP 安装</el-menu-item>
        <el-menu-item index="2-2">APP 贴牌</el-menu-item>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">常用网站</template>
        <el-menu-item index="2-1">在线签名</el-menu-item>
        <el-menu-item index="2-2">证书检测</el-menu-item>
        <el-menu-item index="2-6">UDID查询</el-menu-item>
        <el-menu-item index="2-7">上传分发</el-menu-item>
      </el-submenu>
      <el-menu-item index="4">免费资源</el-menu-item>
      <el-menu-item index="5">代理站对接</el-menu-item>
      <el-menu-item index="6" v-show="wxThemeShow">微信主题管理</el-menu-item>
    </el-menu>
    <img class="logo_app" src="../assets/hot_app.gif" alt="" />
    <!-- <img class="logo_app_v2" src="../assets/hot_app.gif" alt="" /> -->
    <div id="user_avatar" class="app_user_avatar" v-show="!unlogin">
      <el-dropdown trigger="click" @command="handleCommand">
        <el-avatar class="user_avatar" :src="circleUrl"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">{{ email }}</el-dropdown-item>
          <el-dropdown-item command="9"
            >余额 {{ userCoin }} 币</el-dropdown-item
          >
          <el-dropdown-item command="2" divided>管理中心</el-dropdown-item>
          <el-dropdown-item command="10">支付记录</el-dropdown-item>
          <el-dropdown-item command="4">修改邮箱</el-dropdown-item>
          <el-dropdown-item command="3">砸壳进度</el-dropdown-item>
          <el-dropdown-item command="7" divided>邀请返利</el-dropdown-item>
          <el-dropdown-item command="5" divided>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      class="toast_freebtn"
      title="免费资源"
      :visible.sync="dialogFreeVisible"
      width="350px"
    >
      <el-button type="primary" @click="clickSoftFree">网盘资源</el-button>
      <el-button type="primary" @click="onClickFree">每日免费</el-button>
    </el-dialog>

    <el-dialog
      title="微信扫码咨询客服"
      :visible.sync="showKefu"
      width="350px"
      center
    >
      <img class="kefuwx" src="../assets/wx.jpg" />
      <br />QQ群：763789550<br />Telegram: https://t.me/+VGGU8RYVqDo1NTg1
    </el-dialog>
    <el-header>
      <div class="logoAll">
        <img class="logo" src="../assets/newlogo.png" alt="" />
      </div>
      <div id="no-login" v-show="unlogin">
        <el-button
          id="app_login_btn"
          v-show="!isMobile"
          @click="onLogin"
          class="app_login_btn"
          >登录账号</el-button
        >
        <el-button
          id="app_login_btn2"
          v-show="isMobile"
          @click="onLogin"
          class="app_login_btn2"
          >登录账号</el-button
        >
      </div>

      <img
        @click="active_package"
        class="active_package"
        src="../assets/active_package.png"
      />
      <el-button
        class="cer_btn"
        type="danger"
        size="small"
        @click="onClickCerBuy()"
        >获取证书</el-button
      >
      <div id="logined" v-show="!unlogin">
        <el-button @click="onClickBuy" type="warning" class="app_buy_btn"
          >获取|D币</el-button
        >
        <el-tag
          id="user_email"
          v-show="!isMobile"
          class="app_email_text"
          size="small"
          effect="dark"
          type="success"
          @click="handleClick"
          >{{ email }}</el-tag
        >
      </div>
      <el-button
        @click="onClickKefu"
        type="danger"
        size="small"
        class="app_kefu_btn"
        >咨询|进群</el-button
      >
      <el-button
        @click="successDelegate"
        type="warning"
        size="small"
        class="app_delegate_btn"
        >代理|权益</el-button
      >
    </el-header>

    <el-main>
      <el-form>
        <div class="container">
          <div class="block">
            <el-carousel indicator-position="none" height="140px">
              <el-carousel-item
                v-for="(item, index) in notice_user_label"
                :key="index"
              >
                <h3 class="small">{{ item.showName }}</h3>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="app-notice">
            <div
              id="app-notice-content1"
              v-show="!isMobile"
              class="app-notice-content"
            >
              <a style="color: red">DumpApp 网站</a> 是一个专注 iOS
              用户砸壳、签名、APP 等需求的网站<br />{{ phoneInfo }}
            </div>
            <div
              id="app-notice-content2"
              v-show="isMobile"
              class="app-notice-content"
            >
              <a style="color: red"> DumpApp 网站</a> 是一个专注 iOS
              用户砸壳、签名、APP 等需求的网站
            </div>
          </div>
          <div style="margin-top: 100px"></div>
          <el-form-item>
            <div class="top-search-title">
              <a style="font-size: 21px; font-family: 微软雅黑"
                >请输入需要的 APP 名字</a
              >
            </div>
            <div class="selectSearchType">
              <el-radio v-model="radio" label="1">iPhone App</el-radio>
              <el-radio v-model="radio" label="2">iPad App</el-radio>
            </div>
            <div style="margin-top: 15px">
              <el-input
                size="medium"
                placeholder="输入 App Store 中的名字，例如：微信"
                v-model="name"
                class="input-with-select"
              >
                <el-select v-model="value" slot="prepend" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-input>
            </div>
          </el-form-item>
          <div class="upload-button">
            <el-button size="big" type="danger" @click="onSubmit()"
              >开始搜索</el-button
            >
            <el-button size="big" @click="onClickNewUser()" type="warning"
              >用户指南</el-button
            >
            <el-button size="big" type="primary" @click="clickProcess"
              >砸壳进度</el-button
            >
          </div>
        </div>
      </el-form>
    </el-main>
    <el-footer>
      <div class="lifebottom">
        DumpApp
        提供的数据仅供为个人学习、研究或者欣赏作为目的，您需要在合法范围内使用数据，否则
        DumpApp
        有权停止向你提供服务，且不承担因法律允许使用范围外产生的连带责任,使用本网站即代表同意
      </div>
      <div style="margin-top: 10px" class="lifebottom2">
        Copyright © 2022 |
        <a href="https://beian.miit.gov.cn" rel="nofollow"
          >京ICP备2020042182号-1</a
        >
      </div>
    </el-footer>
  </el-container>
</template>

<style>
.top-search-title {
  left: 110px;
}
.selectSearchType {
  left: 0px;
  margin-left: 0px;
  margin-bottom: 40px;
}
.logo {
  height: 63px;
  width: 300px;
}
.el-header {
  text-align: center;
  line-height: 160px;
}

.el-footer {
  text-align: center;
  line-height: 30px;
}

.el-main {
  text-align: center;
  line-height: 160px;
  margin-top: 30px;
}

.logoAll {
  padding-top: 40px;
}

.el-input {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-select {
  width: 90px;
}

.input-with-select .el-input-group__prepend {
  color: #fff;
  background-color: rgb(0, 0, 0);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.app-notice {
  height: 110px;
  width: 90%;
  min-width: 250px;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.24);
}

.app-notice-content {
  padding-top: 20px;
  line-height: 30px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 50px;
}

.app-describe {
  line-height: 40px;
  width: 70%;
  margin: 0 auto;
}
.app_user_avatar {
  top: 10px;
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.user_avatar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.24);
}
.app_manager_btn {
  position: absolute;
  right: 15px;
  top: 73px;
}
.app_email_text {
  position: absolute;
  background: #fff;
  right: 80px;
  top: 18px;
}
.app_dcode_text {
  position: absolute;
  background: #fff;
  right: 75px;
  top: 18px;
}
.app_login_btn {
  position: absolute;
  background: #fff;
  right: 30px;
  top: 10px;
}

.app_login_btn2 {
  position: absolute;
  background: #fff;
  right: 15px;
  top: 130px;
}

.new_user_notice {
  position: absolute;
  left: 10px;
  top: 70px;
}

.app-fixed {
  position: absolute;
  right: 10px;
  top: 170px;
}

.app_kefu_btn {
  position: absolute;
  left: 15px;
  top: 73px;
}

.app_delegate_btn {
  position: absolute;
  left: 95px;
  top: 73px;
}

.app_buy_btn {
  position: absolute;
  right: 100px;
  top: 73px;
}

.logo_app {
  height: 40px;
  width: 40px;
  left: 32px;
  top: -12px;
  position: absolute;
}

.logo_app_v2 {
  height: 40px;
  width: 40px;
  left: 165px;
  top: -0px;
  /* top: 20px; */
  position: absolute;
}

.cer_btn {
  position: absolute;
  right: 15px;
  top: 73px;
}

.active_package {
  position: absolute;
  right: 30px;
  top: 123px;
  width: 50px;
  height: 50px;
}

.app_free_btn {
  position: absolute;
  left: 100px;
  top: 73px;
}

.app_invite_btn {
  position: absolute;
  right: 15px;
  top: 123px;
}

.kefuwx {
  width: 260px;
  align-content: center;
}

.app_back_btn {
  position: absolute;
  background: #fff;
  left: 22px;
  top: 20px;
}

.buy_btn {
  position: absolute;
  left: 95px;
  top: 73px;
}
</style>

<script>
import { upgradeRole } from "@/api/member";
import { getConfig } from "@/api/config.js";
import { postLogout } from "@/api/login";
import { getSelf, freeAction } from "@/api/member";
import { saveUserInfo } from "@/utils/util";
import { Alert, Message } from "element-ui";
export default {
  data() {
    return {
      freeTime: "",
      notice_user_label: ["11", "22"],
      circleUrl:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201411%2F12%2F20141112224259_V8wWX.thumb.1000_0.gif&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671118353&t=128c0d0675fb2274ae1401be0005a09b",
      name: "",
      activeIndex: "0",
      options: [
        {
          value: "1",
          label: "中国区",
        },
        {
          value: "2",
          label: "美国区",
        },
        {
          value: "3",
          label: "香港区",
        },
        {
          value: "4",
          label: "日本区",
        },
        {
          value: "5",
          label: "韩国区",
        },
      ],
      value: "1",
      email: "",
      downUrl: "",
      radio: "1",
      isMobile: false,
      showKefu: false,
      phoneInfo: "DumpApp 已经上线手机端APP，欢迎进行下载使用！",
      unlogin: false,
      dialogFreeVisible: false,
      pay_count: 0,
      userCoin: 0,
      playstore: "",
      isGuest: false,
      isDelegate: false,
      wxThemeShow: false
    };
  },
  methods: {
    formatDate(nS) {
      return new Date(parseInt(nS) * 1000)
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
    },
    async getFreeAction() {
      var newArray = [];
      var freeData = await freeAction();

      for (var i = 0; i < freeData.data.length; i++) {
        var data = freeData.data[i];

        if (data.member == null) {
          console.log("data :", data);
        }

        var email = data.member.email;
        var created_at = data.member.created_at;
        var ipa_name = data.ipa_name;

        var ipaData = {
          email: email,
          created_at: created_at,
          ipa_name: ipa_name,
          showName: email + "  " + ipa_name,
        };

        newArray.push(ipaData);
      }

      this.notice_user_label = newArray;
    },

    async getMemberSelf() {
      // 拿到请求数据 json
      var data = await getSelf();
      
      // 判断是否登录成功
      if (data != null) {
        this.userId = data.id;
        this.userCoin = data.pay_count;

        this.email = data.email;
        this.downUrl = data.invite_url;
        this.unlogin = false;

        if (data.type == "guest") {
          this.isGuest = true;
          this.email = "点击绑定账号，免费送币";
        } else {
          this.isGuest = false;
        }

        if (data.role == "agent") {
          this.isDelegate = true;
        } else {
          this.isDelegate = false;
        }

        this.playstore = data.share_info.activation_code_url;
        this.wxThemeShow = data.is_wx_theme_creator;
        // 保存用户信息
        saveUserInfo(data);
      } else {
        this.unlogin = true;
      }

      this.getFreeAction();
    },

    clickSoftFree() {
      window.open("https://pan.dumpapp.com");
      this.dialogFreeVisible = false;
    },

    isMobileDrive() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onClickInvite() {
      if (this.email.length > 1) {
        this.$router.push({
          path: `/user`,
        });
      } else {
        alert(
          "邀请用户可永久持续获得所有被邀请人充值金额 10% D币的返利, 请先确保已经登录"
        );
      }
    },

    async buyDelegate() {
      var data = await upgradeRole();

      if (data.role == "agent") {
        alert("恭喜您，已经成为代理，享受超多优惠！");
        window.location.reload();
      }
    },

    active_package() {
      this.$router.push({
        path: `/activityList`,
      });
    },

    successDelegate() {
      if (this.isDelegate) {
        window.open(
          "https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd"
        );
        return;
      }

      this.$confirm(
        '是否支付 <strong><font color="#FF0000">288币</font></strong>，成为尊享永久VIP代理？<br>代理享受，<strong><font color="#FF0000">证书低至1元</font></strong>。<br><a href="https://dumpappdoc.feishu.cn/docx/PHbadYcJToYvLBxfshMcs0qznqd" target="_blank" style="color: #3498db; text-decoration: underline;">点击查看，查看代理权益介绍</a>',
        "成为VIP代理",
        {
          confirmButtonText: "立即成为代理",
          cancelButtonText: "稍后决定",
          dangerouslyUseHTMLString: true,
          type: "warning",
        }
      )
        .then(() => {
          this.buyDelegate();
        })
        .catch(() => {
          // 用户点击了取消或关闭对话框
        });
    },

    handleClick() {
      if (this.isGuest) {
        this.$router.push({
          path: `/register?guest=1`,
        });
      }
    },
    onExitLogin() {
      // 清空 cookie
      window.sessionStorage.clear();
      postLogout();
      // 延时 500 毫秒，回到首页
      var self = this;
      setTimeout(function () {
        self.$router.go(0);
      }, 1500);
    },

    handleSelect(key, keyPath) {
      var section = keyPath[0];
      console.log(key);

      if (section == 1) {
        if (key == "2-1") {
          this.$router.push({
            path: `/app`,
          });
        }
        if (key == "2-2") {
          window.open(
            "https://dumpappdoc.feishu.cn/docx/Is8SdklvwoSZnOxZTTycci6unxf"
          );
        }
      }
      if (section == 2) {
        if (key == "2-1") {
          window.open("https://sign.dumpapp.com");
        }
        if (key == "2-2") {
          window.open("https://check.dumpapp.com");
        }
        if (key == "2-4") {
          window.open(this.playstore);
        }
        if (key == "2-6") {
          this.$router.push({
            path: `/udidSearch`,
          });
        }
        if (key == "2-7") {
          this.$router.push({
            path: `/app/publishlist`,
          });
        }
      }

      if (key == 4) {
        this.onClickDayFree();
      }

      if (key == 5) {
        window.open(
          "https://pan.dumpapp.com/%E4%BB%A3%E7%90%86%E7%AB%99%E6%90%AD%E5%BB%BA"
        );
      }

      if (key == 6) {
        this.$router.push({
          path: `/theme/list`,
        });
      }
    },

    onLogin() {
      this.$router.push({
        path: `/login`,
      });
    },

    onClickKefu() {
      if (this.userId == undefined) {
        this.userId = "";
      }

      if (this.email == undefined) {
        self.email = "";
      }

      var kefuUrl =
        "https://dumpappdoc.feishu.cn/docx/Uw3jdK9TZoqFksxvalYcvkNSnmb";
      window.open(kefuUrl);
    },

    onClickBuy() {
      this.$router.push({
        path: `/pay`,
      });
    },

    clickProcess() {
      this.$router.push({
        path: `/userOrder`,
      });
    },
    showtime() {
      var nowTime = new Date();
      nowTime.setMonth(nowTime.getMonth());
      var nowTime2 = new Date(
        nowTime.getFullYear(),
        nowTime.getMonth(),
        nowTime.getDate(),
        12,
        0,
        0
      );
      var endtime = new Date(nowTime2.setHours(nowTime2.getHours() + 24));

      var lefttime = endtime.getTime() - nowTime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = this.checkTime(Math.floor((lefttime / (1000 * 60)) % 60)), //计算分钟数
        lefts = this.checkTime(Math.floor((lefttime / 1000) % 60)); //计算秒数
      return "( " + lefth + ":" + leftm + ":" + lefts + " )";
    },

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    dateTimeToString(date) {
      var y = date.getFullYear();
      var M = date.getMonth() + 1;
      var d = date.getDate();
      var H = date.getHours();
      var m = date.getMinutes();
      var s = date.getSeconds();
      return (
        y +
        "-" +
        (M < 10 ? "0" + M : M) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (H < 10 ? "0" + H : H) +
        ":" +
        (m < 10 ? "0" + m : m) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },

    onClickFree() {
      this.dialogFreeVisible = false;
      this.$prompt("请输入要砸壳的 APP 名字", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.value = 1;
          this.requestSearchApp(value, 1);
        })
        .catch(() => {});
    },

    onClickDayFree() {
      this.dialogFreeVisible = true;
    },

    onclickManager() {
      this.$router.push({
        path: `/user`,
      });
    },

    handleCommand(command) {
      if (command === "1") {
        this.$router.push({
          path: `/register?guest=1`,
        });
      }
      if (command === "2") {
        this.$router.push({
          path: `/user`,
        });
      }
      if (command === "3") {
        this.$router.push({
          path: `/userOrder`,
        });
      }
      if (command === "4") {
        this.$router.push({
          path: `/newEmail`,
        });
      }
      if (command === "5") {
        this.onExitLogin();
      }
      if (command === "7") {
        this.$router.push({
          path: `/user`,
        });
      }
      if (command === "10") {
        this.$router.push({
          path: `/userRecord`,
        });
      }
    },

    onClickNewUser() {
      window.open(
        "https://dumpappdoc.feishu.cn/docs/doccnOxMa8o6Rzm6WpXMnKajjAg"
      );
    },

    getCountryCode() {
      if (this.value == "1") {
        return "cn";
      }
      if (this.value == "2") {
        return "us";
      }
      if (this.value == "3") {
        return "hk";
      }
      if (this.value == "4") {
        return "jp";
      }
      if (this.value == "5") {
        return "kr";
      }
      return "cn";
    },

    async onSubmit() {
      if (this.name === "") {
        Message({
          message: "请输入正确的 APP 名称 或 App Store 链接！",
          type: "error",
          duration: 1.5 * 1000,
          offset: document.body.clientHeight * 0.5 - 100,
        });
        return;
      }

      this.requestSearchApp(this.name, 0);
    },

    // 搜索 apup
    async requestSearchApp(name, isFree) {
      this.$router.push({
        path:
          `/searchAppstore?name=` +
          name +
          "&code=" +
          this.getCountryCode() +
          "&ipad=" +
          this.radio +
          "&isFree=" +
          isFree,
      });
    },

    async onSubmitCrack() {
      this.$router.push({
        path: `/crackpage`,
      });
    },

    async onClickCerBuy() {
      this.$router.push({
        path: `/cerBuy`,
      });
    },

    changeFreeTime() {
      this.freeTime = this.showtime();
    },

    openAlertMsg(data) {
      this.setCookie("new_user_notice", "show", 365);
      var confirmStr = "查看";
      this.$confirm(
        "<strong>" + '<font color="#FF0000">' + data + "</font> " + "</strong>",
        "提示",
        {
          confirmButtonText: confirmStr,
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          window.open(
            "https://dumpappdoc.feishu.cn/docs/doccnOxMa8o6Rzm6WpXMnKajjAg"
          );
          this.requestSearchApp(this.name, 0);
        })
        .catch(() => {});
    },

    setCookie(name, value, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toGMTString();
      document.cookie = name + "=" + value + "; " + expires;
    },

    async getWebConfig() {
      var data = await getConfig();
      var notice = data.announcement.description;
      this.phoneInfo = notice;
    },

    initUrl() {
      const isHttps = "https:" === window.location.protocol ? true : false;
      const host = window.location.host;
      if (!isHttps && host.indexOf("localhost") < 0) {
        let url = window.location.href;
        url = url.replace("http:", "https:");
        window.location.href = url;
      }
    },
    getCookie(name) {
      let ret, m;
      if (typeof name === "string" && name !== "") {
        if (
          (m = String(document.cookie).match(
            new RegExp("(?:^| )" + name + "(?:(?:=([^;]*))|;|$)")
          ))
        ) {
          ret = m[1] ? decodeURIComponent(m[1]) : "";
        }
      }
      return ret;
    },
  },
  mounted() {
    this.initUrl();

    window.setInterval(this.changeFreeTime, 1000);

    // 获取当前用户状态
    this.getMemberSelf();

    if (this.isMobileDrive()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.getWebConfig();
  },
};
</script>
